import { useState } from "react"
import tw from "twin.macro"
import { Check } from "../Icons/Check"
import { UpArrow } from "../Icons/UpArrow"

const Section: React.FC<{ header: string; id: string; selection?: string }> = ({
  header,
  id,
  children,
  selection,
}) => {
  const [open, setOpen] = useState(null)
  const completed = selection !== null
  return (
    <div id={id} css={[tw`col-span-1 lg:col-span-7 text-gray-700 `]}>
      <div
        css={[tw`grid grid-cols-7 mt-3 bg-white/50 p-2`]}
        onClick={() => setOpen(!open)}
      >
        <p css={[tw`col-span-6`]}>{header}</p>
        <div
          css={[tw`flex justify-center items-start content-start col-span-1`]}
        >
          {" "}
          <UpArrow
            css={
              open
                ? [tw`transition-transform duration-500  `]
                : [tw`rotate-180 transition-transform duration-500 `]
            }
          />{" "}
        </div>
        {completed && !open ? (
          <div css={[tw`text-red-300 flex mt-3 col-span-7 `]}>
            <Check css={[tw`h-6  w-6 mr-4 mb-3 `]} /> {selection}
          </div>
        ) : null}
      </div>
      <div
        css={
          open
            ? [
                tw`bg-white/50 p-5 max-h-full ease-in-out transition duration-500`,
              ]
            : [tw`max-h-0 ease-in-out transition-transform duration-500`]
        }
      >
        {open && <div>{children}</div>}
      </div>
    </div>
  )
}

export default Section
